.HomeHodNavbar {
    background-color: var(--bg-sub-blue);
    padding: 10px;
    position:sticky;
    width: 100%;
    top: 0;
    z-index: 999;
}

.HomeHodNavbar .hod-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #fff;
    margin-bottom: 0rem;
    text-align: center;
}

.HomeHodNavbar .coachbox {
    height: 32px;
    position: relative;
    width: 32px;
    margin: 0 auto;
}

.HomeHodNavbar .coachbox-img {
    background-color:  var(--bgWhite);
    border: 1px solid #e8edf7;
    border-radius: 52px;
    display: block;
    height: 32px;
    object-fit: cover;
    width: 32px;
}

.HomeHodNavbar .coach-name{
    font-family: "Open Sans", sans-serif;
    font-size: calc(8px + 1vw);
    font-weight: 500;
    color: #7E7E7E;
    margin-bottom: 0;
    margin-top: .2rem;
}

.HomeHodNavbar .tick-icon{
    height: 12px;
    right: 0px;
    position: absolute;
    bottom: 0px;
}


.back-btn-home{
    color: #fff !important;
    background: transparent !important;
    border: 0px solid #bbc3ca !important;
    font-size: 32px !important;
    padding: 0px 2px !important;
    border-radius: 6px !important;
    line-height: 0 !important;
    font-weight: 600 !important;
}
  
.back-btn-home:hover {
    color: #fff !important;
    background: transparent !important;
    border:0px solid transparent!important;
}
  