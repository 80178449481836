
.WaterLogEntry {
    background-color: #ffffff;
    opacity: 1;
    padding: 20px 0px;
    /* border-radius: 20px 20px 0 0; */
}

.WaterLogEntry .main-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.WaterLogEntry .waterlog-icon {
    height: 64px;
}

.WaterLogEntry .waterlog-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(7px + 1vw);
    font-weight: 700;
    color: #777777;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
}

.WaterLogEntry .waterlog-title.active {
    font-weight: 700;
    color: #333;
}

.WaterLogEntry .water-main-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.WaterLogEntry .water-main-title span{
    color: #777777;
    font-weight: 400;
}

.WaterLogEntry .form-label {
    font-family: "Open Sans", sans-serif;
    font-size: calc(9px + 1vw);
    font-weight: 700;
    color: #777777;
}

.WaterLogEntry .form-label1 {
    font-family: "Open Sans", sans-serif;
    font-size: calc(9px + 1vw);
    font-weight: 500;
    color: #777777;
    margin-bottom: 0.3rem;
}

.WaterLogEntry .water-detail-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #777777;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
}

 .water-icon1 {
    height: 42px;
}

.WaterLogEntry .row.py-3.border-bottom:last-child {
    border: 0 !important;
    padding-bottom: 0rem !important;
}

.floating-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Ensure the loader appears above other content */
  }
  
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db; /* Loader color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }