.UpcomingCard{
    background-color: var(--body-bg);
    padding: 10px;
}

.UpcomingCard .bg-appointment-card {
    background-color: #fff;
    border-radius: 16px;
    padding: 14px;
    border: 0.5px solid #D3D3D3;
}

.UpcomingCard .upcoming-title {
    font-size: calc(10px + 1vw);
    font-weight: 600;
    color: #242424;
    margin-bottom: 0rem;
}


.UpcomingCard .upcoming-subtitle {
    font-size: calc(9px + 1vw);
    font-weight: 500;
    color: #7B7B7B;
    margin-bottom: 0rem;
    line-height: 1.5;
}