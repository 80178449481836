.CareNavigatorProfile {
    background-color: var(--bgGrey);
}

.CareNavigatorProfile .CareNProfile-title {
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}


.CareNavigatorProfile .CareNavigatorProfile-box{
    background-color: var(--bgWhite);
    border-radius: 16px;
    padding: 0px;
    width: 100%;
    border: 0.5px solid #AEBED0;
}

.CareNavigatorProfile .CareNProfile-img-box {
    background-color: #F8DAEA;
    border-radius: 16px;
    height: 200px;
    width: 100%;
    /* padding: 10px; */
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.CareNavigatorProfile .CareNProfile-img-box img {
    display: block;
    border-radius: 16px;
    object-fit: cover;
    height: 200px;
    width: 100%;
}

.CareNavigatorProfile .CareNProfile-main-title {
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #141414;
    margin-bottom: 0rem;
    text-align: center;
}

.CareNavigatorProfile .CareNProfile-main-subtitle {
    font-size: calc(10px + 1vw);
    font-weight: 500;
    color: #9D9D9D;
    margin-bottom: 0rem;
    line-height: 1.3;
    text-align: center;
}


.CareNavigatorProfile .CareNProfile-box-gredient{
    width: 100%;
    padding: 6px 10px;
    border-radius: 0 0 14px 14px;
}

.CareNavigatorProfile .CareNProfile-box-content {
    font-size: calc(10px + 1vw);
    font-weight: 500;
    color: #555555;
    margin-bottom: 0rem;
    line-height: 1.3;
}

.CareNavigatorProfile .CareNProfile-box-content span{
    font-weight: 700;
}

.CareNavigatorProfile .swiper-slide {
    width: 86%;
}

.CareNavigatorProfile .swiper-pagination {
    position: fixed;
    bottom: 1rem;
}

.swiper-pagination-bullet {
    background: #327AC8;
}