
.HRACard .bg-main-blue{
    background-color: #327AC8;
    border-radius:16px;
}

.HRACard .bg-main-card{
    background-color: #fff;
    border-radius:16px;
    padding: 10px;
    border: 0.5px solid #D3D3D3;
}

.HRACard .hra-main-title{
    font-size: calc(14px + 1vw);
    font-weight: 700;
    color: #2F2F2F;
    margin-bottom: 0rem;
}

.HRACard .hra-main-title span{
    color: #F8BE07;
}

.HRACard .hra-content-text{
    font-size: calc(9px + 1vw);
    font-weight: 400;
    color: #2F2F2F;
    margin-bottom: 0rem;
}

.HRACard .hra-sub-title{
    font-size: calc(11px + 1vw);
    font-weight: 600;
    color: #fff;
    margin-bottom: 0rem;
}

.view-report-btn {
    text-decoration: underline;
    font-weight: 600;
    color:#fff;
    font-size: calc(9px + 1vw) !important;
}

.view-report-btn:hover {
    color:#03335b ;
    text-decoration: none;
}