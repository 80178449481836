
.StatsWater .bg-main-StatsWater-card{
    background-color: #fff;
    border-radius:16px;
    padding: 10px;
    border: 0.5px solid #C9C9C9;
}

.StatsWater .StatsWater-main-title{
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}

.StatsWater .StatsWater-sub-title{
    font-size: calc(11px + 1vw);
    font-weight: 400;
    color: #868686;
    margin-bottom: 0rem;
    line-height: 1.4;
}

.StatsWater .StatsWater-content-title{
    font-size: calc(13px + 1vw);
    font-weight: 600;
    color: #151515;
    margin-bottom: 0rem;
    line-height: 1.4;
}

.StatsWater .StatsWater-content-title span{
    font-size: calc(8px + 1vw);
    font-weight: 600;
    color: #868686;
}

.StatsWater .border-bottom-line{
   border-bottom: 1px solid #e7e7e7;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
}

.StatsWater .border-bottom-line:first-child{
    padding-top: 0rem;
}

.StatsWater .border-bottom-line:last-child{
    border-bottom: 0px solid #e7e7e7;
    padding-top: 0rem;
    padding-bottom: 0rem;
 }