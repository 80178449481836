.Arena .modal-title {
    color: #327AC8;
    font-size: 26px;
    font-weight: 600;
}

.Arena .modal-subtitle {
    color: #404040;
    font-size: 18px;
    font-weight: 400;
}
.Arena .Arena-title {
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;}