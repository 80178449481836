
.SleepLogEntry {
    background-color: #ffffff;
    opacity: 1;
    padding: 20px 0px;
    /* border-radius: 20px 20px 0 0; */
}

.SleepLogEntry .main-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.SleepLogEntry .sleep-box{
    border: 1px solid #E5E5E5;
    border-radius: 30px;
    padding: 10px;
    width: 100%;
    display: block;
}

.SleepLogEntry .sleep-icon-new{
    height: 24px;
}

.SleepLogEntry input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    color: #777;
    display: none;
}

.SleepLogEntry .sleep-box-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 500;
    color: #777;
    margin-bottom: 0rem;
}

.SleepLogEntry .sleep-box-title1{
    font-weight: 600;
    font-size: calc(14px + 1vw);
    color: #2E2F30;
    border: 0;
    padding: 0;
    text-align: right !important;
    background: transparent !important;
    appearance: none !important;
}

.SleepLogEntry .sleeplog-icon {
    height: 54px;
}

.SleepLogEntry .sleeplog-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(7px + 1vw);
    font-weight: 700;
    color: #777777;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
}

.SleepLogEntry .sleeplog-title.active {
    font-weight: 700;
    color: #333;
}

.SleepLogEntry .sleep-main-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.SleepLogEntry .sleep-main-title span{
    color: #777777;
    font-weight: 400;
}

.SleepLogEntry .form-label {
    font-family: "Open Sans", sans-serif;
    font-size: calc(9px + 1vw);
    font-weight: 700;
    color: #777777;
}

.SleepLogEntry .form-label1 {
    font-family: "Open Sans", sans-serif;
    font-size: calc(9px + 1vw);
    font-weight: 500;
    color: #777777;
    margin-bottom: 0.3rem;
}

.SleepLogEntry .form-label2 {
    font-family: "Open Sans", sans-serif;
    font-size: calc(9px + 1vw);
    font-weight: 600;
    color: #333;
    margin-bottom: 0rem;
}

.SleepLogEntry .sleep-detail-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #777777;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
}

.sleep-icon1 {
    height: 42px;
}

.SleepLogEntry .row.py-3.border-bottom:last-child {
    border: 0 !important;
    padding-bottom: 0rem !important;
}

.floating-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Ensure the loader appears above other content */
  }
  
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db; /* Loader color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }