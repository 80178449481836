.UpcomingAppointments {
    background-color: var(--bgGrey);
}

.UpcomingAppointments .Appointments-title {
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}


.UpcomingAppointments .UpcomingAppointments-box{
    background-color: var(--bgWhite);
    border-radius: 14px;
    padding: 0px;
    width: 100%;
}

.UpcomingAppointments .Appointments-img-box {
    background-color: #F8DAEA;
    border-radius: 10px;
    height: 70px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.UpcomingAppointments .Appointments-main-title {
    font-size: calc(10px + 1vw);
    font-weight: 600;
    color: #F27EBB;
    margin-bottom: 0rem;
    text-align: center;
}

.UpcomingAppointments .Appointments-main-subtitle {
    font-size: calc(9px + 1vw);
    font-weight: 500;
    color: #C48DAA;
    margin-bottom: 0rem;
    text-align: center;
}


.UpcomingAppointments .Appointments-box-gredient{
    width: 100%;
    padding: 6px 10px;
    border-radius: 0 0 14px 14px;
}

.UpcomingAppointments .Appointments-box-date {
    font-size: calc(8px + 1vw);
    font-weight: 500;
    color: #6F6F6F;
    margin-bottom: 0rem;
}
