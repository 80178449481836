
.CoachRatingCard .bg-main-CoachRating-card{
    background-color: #fff;
    border-radius:16px;
    padding: 10px;
    border: 0.5px solid #C9C9C9;
}

.CoachRatingCard .CoachRating-main-title{
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #151515;
    margin-bottom: 0rem;
}
