.ScheduleCall{
    background-color: var(--body-bg);
    padding: 10px;
}

.ScheduleCall .maintitle{
    font-size: calc(16px + 1vw);
    font-weight: 700;
    color: #327AC8;
    margin-bottom: 0rem;
}
