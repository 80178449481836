
.UpcomingCall .bg-main-UC-card{
    background-color: #AECEE8;
    border-radius:16px;
    padding: 10px;
    border: 0px solid #D3D3D3;
}

.UpcomingCall .UC-main-title{
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}

.UpcomingCall .UC-sub-title{
    font-size: calc(11px + 1vw);
    font-weight: 400;
    color: #151515;
    margin-bottom: 0rem;
    line-height: 1.3;
}

.UpcomingCall .UC-sub-title span{
    font-weight: 600;
}

.UpcomingCall .CNbox {
    height: 64px;
    position: relative;
    width: 64px;
    margin: 0 auto;
}

.UpcomingCall .CNbox-img {
    background-color: var(--bgWhite);
    border: 2px solid #327AC8;
    border-radius: 52px;
    display: block;
    height: 64px;
    object-fit: cover;
    width: 64px;
}