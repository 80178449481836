.home-card{
    background-color: var(--body-bg);
}

.home-card .homecard-bg{
    background-image: url(../../images/homecard-bg.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    overflow: hidden;
    height: 200px;
}

.home-card .main-title{
    font-size: calc(11px + 1vw);
    font-weight: 600;
    color: #fff;
    margin-bottom: 0rem;
}

.home-card .main-subtitle{
    font-size: calc(10px + 1vw);
    font-weight: 600;
    color: #fff;
    margin-bottom: 0rem;
}

.enroll-now-btn {
    color: #03335b !important;
    background: #fff !important;
    border: 1px solid #fff !important;
    font-size: calc(10px + 1vw) !important;
    padding: 6px 10px !important;
    border-radius:14px !important;
    line-height: 1 !important;
    font-weight: 600 !important;
}
  
.enroll-now-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}