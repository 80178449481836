.Play{

}

.Play .modal-title {
    color: #327AC8;
    font-size: 26px;
    font-weight: 600;
}

.Play .modal-subtitle {
    color: #404040;
    font-size: 18px;
    font-weight: 400;
}
.Play .Play-title {
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;}

.Play .col-6 {
    width: 100%;
    margin-bottom: 1rem;
}

.Play .Videos-img {
    height: 200px;
}