
.HealthLockerCard .bg-main-LC-card{
    background-color: #fff;
    color: #242424;
    border-radius:16px;
    padding: 10px;
    border: 0.5px solid #D3D3D3;
    cursor: pointer;
    transition: all 0.3s ease-in-out;;
}

.HealthLockerCard .bg-main-LC-card:hover{
    background-color: #1279be;
    border-radius:16px;
    padding: 10px;
    border: 0.5px solid #1279be;
    color: #fff;
    transition: all 0.3s ease-in-out;;
}

.HealthLockerCard .LC-main-title{
    font-size: calc(11px + 1vw);
    font-weight: 500;
    margin-bottom: 0rem;
}

.HealthLockerCard .LC-sub-title{
    font-size: calc(11px + 1vw);
    font-weight: 400;
    color: #151515;
    margin-bottom: 0rem;
    line-height: 1.3;
}

.HealthLockerCard .LC-sub-title span{
    font-weight: 600;
}

.HealthLockerCard .arrow-icon{
    font-size: calc(18px + 1vw);
    color: #707070;
}


.HealthLockerCard .pdf-icon{
    font-size: calc(18px + 1vw);
    color: #327AC8 ;
}


.close-btn{
    background-color: #E0E0E0;
    fill: #707070 !important;
    padding: 4px;
    border-radius: 20px;
    font-size: calc(10px + 1vw);
}

.close-btn:hover{
    background-color: #1279be ;
    fill: #fff !important;
}

.HealthLockerCard .offcanvas-title {
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}

.HealthLockerCard .table-responsive {
    border-radius: 14px;
}

.HealthLockerCard .table td,.HealthLockerCard .table th{
    background-color: #e9e9e9;
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.HealthLockerCard .bg-td-green {
    background-color: #dcfffb !important;
}


.HealthLockerCard .offcanvas {
    height: 100vh !important;
    --bs-offcanvas-height: 100vh !important;
}
.HealthLockerCard .offcanvas-body {
    height: auto !important;
    --bs-offcanvas-height: auto !important;
    max-height: 90vh !important;;
}


.HealthLockerCard .hl-sub-title{
    font-size: calc(9px + 1vw);
    font-weight: 400;
    color: #868686;
    margin-bottom: 0rem;
    line-height: 1.4;
}

.HealthLockerCard .hl-content-title{
    font-size: calc(10px + 1vw);
    font-weight: 600;
    color: #151515;
    margin-bottom: 0.2rem;
    line-height: 1.4;
}

.HealthLockerCard .hl-content-title span{
    font-size: calc(8px + 1vw);
    font-weight: 600;
    color: #868686;
}

.HealthLockerCard .border-bottom-line{
   border-bottom: 1px solid #e7e7e7;
   padding-top: 0.7rem;
   padding-bottom: 0.7rem;
}

.HealthLockerCard .border-bottom-line:first-child{
    padding-top: 0rem !important;
}

.HealthLockerCard .border-bottom-line:last-child{
    border-bottom: 0px solid #e7e7e7;
    padding-top: 0.7rem;
    padding-bottom: 0rem !important;
 }

 .HealthLockerCard .bg-health-locker-card{
    background-color: var(--body-bg);
    border-radius:16px;
    padding: 10px;
    border: 0.5px solid #C9C9C9;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
}