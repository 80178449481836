
.CareNavigatorSlotProfile .bg-main-CNSlotProfile-card{
    background-color:transparent;
    /* border-radius:16px; */
    padding: 0px;
    border: 0px solid #C9C9C9;
}

.CareNavigatorSlotProfile .CNSlotProfile-main-title{
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #141414;
    margin-bottom: 0rem;
}

.CareNavigatorSlotProfile .CNSlotProfile-sub-title{
    font-size: calc(11px + 1vw);
    font-weight: 400;
    color: #868686;
    margin-bottom: 0rem;
    line-height: 1.4;
}

.CareNavigatorSlotProfile .CNSlotProfile-sub-title span{
    font-weight: 500;
    color: #151515;
}

.CareNavigatorSlotProfile .CNSlotProfile-box {
    height: 100px;
    position: relative;
    width: 100px;
    margin: 0 auto;
}

.CareNavigatorSlotProfile .CNSlotProfile-box-img {
    background-color: var(--bgWhite);
    border: 2px solid #327AC8;
    border-radius: 120px;
    display: block;
    height: 100px;
    object-fit: cover;
    width: 100px;
}