.HRAViewReport{
    background-color: var(--body-bg);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
}

.HRAViewReport .submaintitle{
    font-size: calc(14px + 1vw);
    font-weight: 400;
    color: #141414;
    margin-bottom: 0rem;
}

.HRAViewReport .strip{
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #141414;
    line-height: 10px;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}

.HRAViewReport .strip-gap {
    margin-top: 0px !important;
    line-height: 0px !important;
    margin-bottom: 1px !important;
    font-size: 25px !important;
    color:  #D8DDE3;
    
}

.HRAViewReport .hraPointer {
    background-color: #D8DDE3;
    border-radius: 6px;
    padding: 4px 6px;
}


.HRAViewReport .report-maintitle{
    font-size: calc(20px + 1vw);
    font-weight: 700;
    margin-bottom: 0rem;
}


.HRAViewReport .text-High{
    color: #eb443b!important;
}

.HRAViewReport .text-Moderate{
   color: #fabe04!important;
}

.HRAViewReport .text-Healthy{
   color: #4486f7!important;
}

.HRAViewReport .text-Optimum{
    color: #008100;
}


.HRAViewReport .bg-High{
    background-color: #eb443b!important;
    font-size: calc(5px + 1vw);
    font-weight: 700;
}

.HRAViewReport .bg-Moderate{
    background-color: #fabe04!important;
    font-size: calc(5px + 1vw);
    font-weight: 700;
}

.HRAViewReport .bg-Healthy{
    background-color: #4486f7!important;
    font-size: calc(5px + 1vw);
    font-weight: 700;
}

.HRAViewReport .bg-Optimum{
    background-color: #008100;
    font-size: calc(5px + 1vw);
    font-weight: 700;
}

.HRAViewReport .view-report-content{
    font-size: calc(11px + 1vw);
    font-weight: 400;
    color: #747474;
    margin-bottom: 0rem;
}

.HRAViewReport .view-report-content span{
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #141414;
}