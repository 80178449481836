.MedicineOrderStats {
    background-color: var(--bgGrey);
}

.MedicineOrderStats .MedicineOrder-title {
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}


.MedicineOrderStats .MedicineOrderStats-box{
    background-color: var(--bgWhite);
    border-radius: 14px;
    padding: 0px;
    width: 100%;
}

.MedicineOrderStats .MedicineOrder-img-box {
    background-color: #FFE0BC;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.MedicineOrderStats .MedicineOrder-main-title {
    font-size: calc(10px + 1vw);
    font-weight: 600;
    color: #E39C4B;
    margin-bottom: 0rem;
    text-align: center;
}

.MedicineOrderStats .MedicineOrder-main-subtitle {
    font-size: calc(9px + 1vw);
    font-weight: 500;
    color: #b36c1c;
    margin-bottom: 0rem;
    text-align: center;
}


.MedicineOrderStats .MedicineOrder-box-gredient{
    width: 100%;
    padding: 6px 10px;
    border-radius: 0 0 14px 14px;
}

.MedicineOrderStats .MedicineOrder-box-date {
    font-size: calc(8px + 1vw);
    font-weight: 500;
    color: #6F6F6F;
    margin-bottom: 0rem;
}
