.SelectYourCareNavigator{
    background-color: var(--body-bg);
    /* padding: 10px; */
}

.SelectYourCareNavigator .maintitle{
    font-size: calc(14px + 1vw);
    font-weight: 700;
    color: #327AC8;
    margin-bottom: 0rem;
}
