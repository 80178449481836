.CareNavigatorSlotBook .calender-box-slot{
    background-color: #B7CFEA;
    border-radius: 40px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CareNavigatorSlotBook .icon-slot-arrow{
    font-size: calc(24px + 1vw);
    padding: 0px 10px;
    border-radius: 40px;
    color: #2F2F2F;
    border: 0;
}

.CareNavigatorSlotBook .slot-book-title{
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
    text-align: center;
}

.CareNavigatorSlotBook .book-box-slot{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}


.btn-box-slot {
    color: #B4B4B4 !important;
    background: #fff!important;
    border: 0.5px solid #AEBED0 !important;
    font-size: calc(10px + 1vw) !important;
    padding: 6px 14px !important;
    border-radius: 6px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
    /* width: 110px !important; */
  }
  
  .btn-box-slot:hover {
    color: #fff!important;
    background: #1279be!important;
    border: 0.5px solid #1279be !important;
  }

  .btn-box-slot-active {
    color: #fff!important;
    background: #1279be!important;
    border: 0.5px solid #1279be !important;
  }
