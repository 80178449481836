.WhatsIncluded{
    padding: 10px;
    margin-bottom: 5rem;
}


.WhatsIncluded .wi-main-title{
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #2F2F2F;
    margin-bottom: 0rem;
}

.WhatsIncluded .wi-content-text{
    font-size: calc(10px + 1vw);
    font-weight: 400;
    color: #2F2F2F;
    margin-bottom: 0rem;
}

.WhatsIncluded .wi-content-text span{
    font-weight: 700;
}

