

.tab-button {
    background-color: #fff; /* Default background color for inactive tabs */
    border: none;
    padding: 12px 30px;
    /* margin-right: 10px; */
    cursor: pointer;
    /* margin-bottom: 1rem; */
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #333;
    border-bottom:3px solid #f3f3f3 ;
    text-transform: uppercase;
  }
  
  .tab-button.active {
    background-color: #fff; /* Background color for active tab */
    color: #2bb943; /* Text color for active tab */
    font-weight: 600;
    border-bottom:3px solid #2bb943 ;
  }

  /* Example CSS for styling tabs and tab panels */
.react-tabs {
  border: 1px solid #ccc;
}

.react-tabs__tab-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.react-tabs__tab {
  padding: 10px 15px;
  cursor: pointer;
  border: 1px solid transparent;
  border-bottom: none;
  background-color: #f0f0f0;
  border-radius: 5px 5px 0 0;
  margin-right: 5px;
}

.react-tabs__tab--selected {
  background-color: #fff;
  border-color: #ccc;
}

.react-tabs__tab-panel {
  padding: 20px;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
}
/* 
.swiper-slide {
  width: auto !important;
} */

.graph-tab{
  width: 100%;
  
}

.btn.active-tab {
  color: #333;
  background-color: #fff !important;
  border-color: transparent;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border-bottom: #2bb943 2px solid !important;
}

.btn.menu-tab {
  color: #3a3a3b;
  background-color: #eee;
  border-color: transparent;
  font-size: calc(11px + 1vw);
  padding: 10px 12px;
  font-weight: 600;
  border-radius:16px 16px 0 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.error-message {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Fill the height of the parent */
}

.error-message p {
  background-color: #ffcccc; /* Example background color */
  padding: 10px;
  border: 1px solid #ff0000; /* Example border color */
  border-radius: 5px;
  color: #ff0000; /* Example text color */
  /* font-weight: bold; */
}
