.HomeBottomMenu {
    background-color: var(--bg-sub-blue);
    padding: 10px 0;
    position:fixed;
    width: 100%;
    bottom: 0;
    z-index: 999;
}

.HomeBottomMenu .hod-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #fff;
    margin-bottom: 0rem;
    text-align: center;
}

.HomeBottomMenu .hod-icon {
    height: 20px;
}

.HomeBottomMenu .hod-icon-plus {
    height: 40px;
}

.menu-bottom-btn{
    color: #fff !important;
    background: transparent !important;
    border: 0px solid #bbc3ca !important;
    font-size: calc(5px + 1vw) !important;
    padding: 0px 0px !important;
    border-radius: 6px !important;
    line-height: 1 !important;
    font-weight: 500 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    margin: 0 auto;
}
  
.menu-bottom-btn:hover {
    color: #fff !important;
    background: transparent !important;
    border:0px solid transparent!important;
    font-weight: 600 !important;
}

.HomeBottomMenu .offcanvas-title {
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}

.HomeBottomMenu div#logsBottomNavbar {
    margin: 1rem !important;
    border: 1px solid #707070!important;
    border-radius: 20px!important;
    margin-bottom: 4.5rem!important;
}
.HomeBottomMenu .offcanvas {
    height: auto !important;
    --bs-offcanvas-height: auto !important;
}
.HomeBottomMenu .offcanvas-body {
    height: auto !important;
    --bs-offcanvas-height: auto !important;
}

.close-btn{
    background-color: #E0E0E0;
    fill: #707070 !important;
    padding: 4px;
    border-radius: 20px;
    font-size: calc(10px + 1vw);
}

.close-btn:hover{
    background-color: #1279be ;
    fill: #fff !important;
}

.menu-log-btn{
    color: #2E2E2E !important;
    background: transparent !important;
    border: 0px solid #bbc3ca !important;
    font-size: calc(9px + 1vw) !important;
    padding: 0px 2px !important;
    border-radius: 6px !important;
    line-height: 1 !important;
    font-weight: 500 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* height: 38px; */
    margin: 0 auto;
}
  
.menu-log-btn:hover {
    color: #1279be  !important;
    background: transparent !important;
    border:0px solid transparent!important;
    font-weight: 600 !important;
}
