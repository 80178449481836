.RecommendedReads {
    background-color: var(--bgGrey);
}

.RecommendedReads .Reads-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}

.RecommendedReads .RecommendedReads-box{
    background-color: var(--bgWhite);
    border-radius: 14px;
    padding: 0px;
    width: 100%;
    height: 140px;
    position: relative;
}

.RecommendedReads .blogs-img {
    border: 1px solid #e8edf7;
    border-radius: 14px;
    display: block;
    height: 140px;
    object-fit: cover;
    width: 100%;
}

.RecommendedReads .box-gredient{
    background: linear-gradient(180deg, rgba(4,5,6,0) 0%, rgba(4,5,6,1) 100%);
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 6px 10px;
    border-radius: 0 0 14px 14px;
}

.RecommendedReads .blog-box-title {
    font-size: calc(9px + 1vw);
    font-weight: 600;
    color: #fff;
    margin-bottom: 0rem;
}

.RecommendedReads .blog-box-title span{
    font-size: calc(7px + 1vw);
}