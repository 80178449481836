.Appointments{
    background-color: var(--body-bg);
    /* padding: 10px; */
}

.Appointments .maintitle{
    font-size: calc(16px + 1vw);
    font-weight: 700;
    color: #327AC8;
    margin-bottom: 0rem;
}

.Appointments .tabs-container {
    width: 100%;
    margin: 0 auto;
}

.Appointments .tabs-header {
    display: flex;
    cursor: pointer;
    box-shadow:0px 3px 6px rgb(110 140 162 / 29%);
}

.Appointments .tab-item {
    flex: 1;
    padding:12px 10px;
    text-align: center;
    border-bottom: 6px solid transparent;
    transition: border-bottom 0.3s ease;
    color: #8F8F8F;
    font-size: calc(14px + 1vw);
    text-transform: capitalize;
    font-weight: 500;
}

.Appointments .tab-item.active {
    border-bottom: 6px solid #327AC8;
    color: #327AC8;
    font-weight: 600;
}

.Appointments .tabs-content {
    margin: 10px;
}

.Appointments .tab-content {
    display: flex;
    flex-direction: column;
}
