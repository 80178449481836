
.CoachDetailsCard .bg-main-CoachDetail-card{
    background-color: #fff;
    border-radius:16px;
    padding: 10px;
    border: 0.5px solid #C9C9C9;
}

.CoachDetailsCard .CoachDetail-main-title{
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}

.CoachDetailsCard .CoachDetail-sub-title{
    font-size: calc(11px + 1vw);
    font-weight: 400;
    color: #868686;
    margin-bottom: 0rem;
    line-height: 1.4;
}

.CoachDetailsCard .CoachDetail-sub-title span{
    font-weight: 600;
    color: #151515;
}

.CoachDetailsCard .CN-box {
    height: 64px;
    position: relative;
    width: 64px;
    margin: 0 auto;
}

.CoachDetailsCard .CN-box-img {
    background-color: var(--bgWhite);
    border: 2px solid #327AC8;
    border-radius: 52px;
    display: block;
    height: 64px;
    object-fit: cover;
    width: 64px;
}