:root {
  --body-bg: #F0F6FD;
  --button-bg: #fff;
  --button-text: #000;
  --button-border: #000;
  --bgWhite: #ffffff;
  --bgBlue: #327AC8; 
  --bgGrey: #F0F6FD;
  --text-color: #333;
  --bgLightGrey: #e5e8eb;
  --text-align: left;
  --bg-main-blue: #03335b;
  --bg-sub-blue: #327AC8; 
  --text-main-blue: #03335b;
  --text-sub-blue: #327AC8; 
}

[data-theme='arabic'] {
  --body-bg: #000;
  --text-color: #fff;
  --button-bg: #000;
  --button-text: #fff;
  --button-border: #fff;
  --text-align: right;
}

body{
  background-color: var(--body-bg);
  font-family: "Open Sans", sans-serif;
}


/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* height */
::-webkit-scrollbar {
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #eee; 
  border-radius: 0px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #03335b; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#03335b; 
}

.bottom-fixed-btn {
  background-color: #ffffff;
  opacity: 1;
  padding: 20px 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.next-btn {
  font-family: "Open Sans", sans-serif;
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b!important;
  font-size: calc(14px + 1vw) !important;
  padding: 12px 6px !important;
  border-radius:14px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  width: 100%;
  height: 100%;
}

.next-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be!important;
}

.back-btn {
  font-family: "Open Sans", sans-serif;
  color: #363636 !important;
  background: #ffffff !important;
  border: 1px solid #7e7e7e !important;
  font-size: calc(14px + 1vw) !important;
  padding: 12px 6px !important;
  border-radius: 14px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  width: 100%;
  height: 100%;
}

.back-btn:hover {
  color: #fff!important;
  background: #1279be!important;
  border: 1px solid #1279be !important;
}

:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(13,110,253,0) !important;
}

.contentbox {
  background-color: #ffffff;
  padding-top: 1.25rem;
}

.point-label{
  background-color: #ffe35b;
    border: 1px solid #f4c53b;
    color: #333;
    border-radius: 30px;
    height: 30px;
    width: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: calc(7px + 1vw) !important;
    padding: 7px 4px;
    text-align: center;
    font-weight: 600;
}

.view-all{
    text-decoration: underline;
    color: #03335b;
    font-weight: 600;
    font-size: calc(8px + 1vw) !important;
}

.view-all:hover {
  color: #1279be;
}

.btn-join {
  font-family: "Open Sans", sans-serif;
  color: #fff !important;
  background: #03335b!important;
  border: 1px solid #03335b!important;
  font-size: calc(10px + 1vw) !important;
  padding: 8px 6px !important;
  border-radius: 20px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  width: 100%;
  height: 100%;
}

.btn-join:hover {
  color: #fff!important;
  background: #1279be!important;
  border: 1px solid #1279be !important;
}

.input-field-ui{
  border: 1px solid #DCDCDC !important;
  padding: 10px 14px ;
  border-radius: 12px !important;
  width: 100% !important;
}

.input-field-ui:focus{
  outline: none !important;
  border: 1px solid #03335b !important;
}

.input-field-ui::placeholder{
  color: #818181 !important;
}

.input-field-ui:focus::placeholder{
  color: transparent !important;
}

.modal-content {
  border-radius: 24px !important;
}


.modal-title {
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
  line-height: 1.2;
}

.modal-header {
  align-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: row !important;
  flex-shrink: 0;
  padding-bottom: 0;
  border: 0;
}

.control-input-ui {
  border: 1px solid #BBBBBB !important;
  border-radius: 14px !important;
  color: #343434 !important;
  font-size: 18px !important;
  background-color: #fdfdfd !important;
  padding: 8px 12px !important;
}

.control-input-ui:disabled {
  background-color: #F5F5F5 !important;
  border: 1px solid #e0e0e0!important;
  opacity: 1;
}


.select-input-ui {
  border: 1px solid #BBBBBB !important;
  border-radius: 14px !important;
  color: #343434 !important;
  font-size: 18px !important;
  background-color: #fdfdfd !important;
  padding: 8px 12px !important;
  width: 100% !important;
}

.select-input-ui:disabled {
  background-color: #F5F5F5 !important;
  border: 1px solid #e0e0e0!important;
  opacity: 1;
}

.select-input-ui:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0) !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0) !important;
}

.form-check-input-ui {
  background-color: #fff !important;
  border: 1px solid #707070 !important;
  border-radius: 2px !important;
  padding: 10px !important;
  margin-right: 10px;
}

.form-check-input-ui:checked {
  background-color: #707070 !important;
  border: 1px solid #707070 !important;
  border-radius: 2px !important;
  padding: 10px !important;
}

.form-check-input-ui:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0) !important;
}

.health-locker-btn{
  color: #03335b !important;
  background: transparent !important;
  border: 1px solid #fff !important;
  font-size: 16px !important;
  padding: 6px 10px !important;
  border-radius: 8px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
}

.health-locker-btn:hover {
  color: #fff !important;
  background: #578FFF !important;
  border: 1px solid #578FFF!important;
}


.btn-enrollNow {
  color: #fff !important;
  background: #03335b!important;
  border: 1px solid #03335b!important;
  font-size: calc(16px + 1vw) !important;
  padding: 12px 16px !important;
  border-radius: 30px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  width: 100%;
}

.btn-enrollNow:hover {
  color: #fff!important;
  background: #1279be!important;
  border: 1px solid #1279be !important;
}

.bottom-fixed-button{
 padding: 10px;
 position: fixed;
 bottom: 0;
 width: 100%;
 left: 0;
 right: 0;
 background-color: var(--body-bg);
}


.btn-join-new {
  font-family: "Open Sans", sans-serif;
  color: #fff !important;
  background: #03335b!important;
  border: 1px solid #03335b!important;
  font-size: calc(12px + 1vw) !important;
  padding: 6px 30px !important;
  border-radius: 20px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
}

.btn-join-new:hover {
  color: #fff!important;
  background: #1279be!important;
  border: 1px solid #1279be !important;
}

.control-input-ui {
  background-color: #fff !important;
  border: 1px solid #D6DDE5 !important;
  border-radius: 10px!important;
  font-weight: 500;
  color: #141414 !important;
  font-size: calc(12px + 1vw) !important;
  padding: 8px 12px !important;
}

.control-input-ui::placeholder{
  color: #a7aeb6 !important;
}

.control-input-ui:focus::placeholder{
  color: transparent !important;
}

.form-check-input-ui {
  background-color: transparent !important;
  border: 1px solid #707070 !important;
  border-radius: 6px !important;
  padding: 12px !important;
  margin-right: 10px;
  width: 1em;
  height: 1em;
}

.form-check-input-ui:checked {
  background-color: #327AC8!important;
  border: 1px solid #327AC8 !important;
  border-radius: 6px !important;
  padding: 12px !important;
}

.form-check-input-ui:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0) !important;
}

.form-radio-input-ui {
  background-color: transparent !important;
  border: 1px solid #707070 !important;
  border-radius: 26px !important;
  padding: 12px !important;
  margin-right: 10px;
  width: 1em;
  height: 1em;
}

.form-radio-input-ui:checked {
  background-color: #327AC8!important;
  border: 1px solid #327AC8 !important;
  border-radius: 26px !important;
  padding: 12px !important;
}

.form-radio-input-ui:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0) !important;
}

.form-label-input{
  font-size: calc(11px + 1vw);
  font-weight: 500;
  color: #141414;
  line-height: 1.4;
}

.btn-hra-next {
  color: #fff !important;
  background: #03335b!important;
  border: 1px solid #03335b!important;
  font-size: calc(14px + 1vw) !important;
  padding: 10px 30px !important;
  border-radius: 20px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  width: 100%;
}

.btn-hra-next:hover {
  color: #fff!important;
  background: #1279be!important;
  border: 1px solid #1279be !important;
}

.btn-hra-back {
  font-family: "Open Sans", sans-serif;
  color: #484848!important;
  background: #D8D8D8!important;
  border: 1px solid #D8D8D8!important;
  font-size: calc(14px + 1vw) !important;
  padding: 10px 30px !important;
  border-radius: 20px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  width: 100%;
}

.btn-hra-back:hover {
  color: #fff!important;
  background: #1279be!important;
  border: 1px solid #1279be !important;
}



.btn-paynow {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: calc(11px + 1vw) !important;
  padding: 4px 6px !important;
  border-radius:20px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  width: 100%;
}


.btn-paynow:hover {
  color: #fff!important;
  background: #1279be!important;
  border: 1px solid #1279be !important;
}
