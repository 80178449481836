.UpcomingLabTests {
    background-color: var(--bgGrey);
}

.UpcomingLabTests .LabTests-title {
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}


.UpcomingLabTests .UpcomingLabTests-box{
    background-color: var(--bgWhite);
    border-radius: 14px;
    padding: 0px;
    width: 100%;
}

.UpcomingLabTests .LabTests-img-box {
    background-color: #E4F8DA;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.UpcomingLabTests .LabTests-main-title {
    font-size: calc(10px + 1vw);
    font-weight: 600;
    color: #94B981;
    margin-bottom: 0rem;
    text-align: center;
}

.UpcomingLabTests .LabTests-main-subtitle {
    font-size: calc(9px + 1vw);
    font-weight: 500;
    color: #668856;
    margin-bottom: 0rem;
    text-align: center;
}

.UpcomingLabTests .LabTests-box-gredient{
    width: 100%;
    padding: 6px 10px;
    border-radius: 0 0 14px 14px;
}

.UpcomingLabTests .LabTests-box-date {
    font-size: calc(8px + 1vw);
    font-weight: 500;
    color: #6F6F6F;
    margin-bottom: 0rem;
}
