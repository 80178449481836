
.FoodLogEntry {
    background-color: #ffffff;
    opacity: 1;
    padding: 20px 0px;
    /* border-radius: 20px 20px 0 0; */
}

.FoodLogEntry .main-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.FoodLogEntry .foodlog-icon {
    height: 42px;
}

.FoodLogEntry .FoodLog-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(7px + 1vw);
    font-weight: 700;
    color: #777777;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
}

.FoodLogEntry .FoodLog-title.active {
    font-weight: 700;
    color: #333;
}

.FoodLogEntry input#inputDescribe {
    border: 0px !important;
    border-bottom: 1px solid #333 !important;
    border-radius: 0px !important;
    /* padding: 0; */
    font-size: calc(12px + 1vw);
    font-weight: 500;
}

.FoodLogEntry input#inputTime {
    border: 0px !important;
    border-bottom: 0px solid #333 !important;
    border-radius: 0px !important;
    padding: 0;
    font-size: calc(12px + 1vw);
    font-weight: 500;
    z-index: 2;
    position: relative;
    background: transparent !important;
    padding-left: 30px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.input-icons i {
    position: absolute;
    font-size: calc(16px + 1vw) !important;
    bottom: -4px;
    left: 0px;
    color: #acacac !important;
    z-index: 1;
}

.icon {
    padding: 10px;
    min-width: 40px;
}

.FoodLogEntry .upload-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(11px + 1vw);
    font-weight: 600;
    color: #777777;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
}


.FoodLogEntry .upload-icon {
    height: 64px;
}

.FoodLogEntry input[type='file']{
    height: 110px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    margin: 0 auto;
  }


video  {
    width: 192px;
    height: 192px;
    background: rgba(0,0,0,0.2);
    -webkit-transform: scaleX(-1); /* mirror effect while using front cam */
    transform: scaleX(-1);         /* mirror effect while using front cam */
  }
  
  #canvas  {
    width: 192px;
    height: 192px;
    -webkit-transform: scaleX(-1); /* mirror effect while using front cam */
    transform: scaleX(-1);         /* mirror effect while using front cam */
  }

  button,
video {
  display: block;
}

.file-input-container {
    position: relative;
    display: inline-block;
  }

.customImage{
    max-width: 100%;
    width: auto;
    /* height: 100%; */

}
  