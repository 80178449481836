
.LogBoxes {
    background-color: var(--bgGrey);
}

.LogBoxes .logs-bx{
    background-color: var(--bgWhite);
    border-radius: 14px;
    padding: 10px;
}

.LogBoxes .logs-icon{
    height: 24px;
}

.LogBoxes .log-box-title {
    font-size: calc(10px + 1vw);
    font-weight: 600;
    color: #242424;
    margin-bottom: 0rem;
}

.LogBoxes .log-box-subtitle {
    font-size: calc(11px + 1vw);
    font-weight: 500;
    color: #898989;
    margin-bottom: 0rem;
}


.LogBoxes .log-pie-title {
    font-size: calc(11px + 1vw);
    font-weight: 600;
    color: #898989;
    margin-bottom: 0rem;
}


.btn-logs {
    font-family: "Open Sans", sans-serif;
    color: #fff !important;
    background: #327AC8 !important;
    border: 1px solid #327AC8 !important;
    font-size: calc(10px + 1vw) !important;
    padding: 3px 6px !important;
    border-radius:20px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
    width: 100%;
    height: 100%;
  }
