.HealthLocker{
    background-color: var(--body-bg);
    padding: 10px;
}

.HealthLocker .maintitle{
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}
