
.WeightLogEntry {
    background-color: #ffffff;
    opacity: 1;
    padding: 20px 0px;
    /* border-radius: 20px 20px 0 0; */
}

.WeightLogEntry .main-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.WeightLogEntry .weight-box{
    border: 1px solid #E5E5E5;
    border-radius: 30px;
    padding: 10px;
    width: 100%;
    display: block;
}

.WeightLogEntry .weight-icon-new{
    height: 24px;
}

.WeightLogEntry input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    color: #777;
    display: none;
}

.WeightLogEntry .weight-box-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 500;
    color: #777;
    margin-bottom: 0rem;
}

.WeightLogEntry .weight-box-title1{
    font-weight: 600;
    font-size: calc(14px + 1vw);
    color: #2E2F30;
    border: 0;
    padding: 0;
    background: transparent !important;
    appearance: none !important;
}

.WeightLogEntry .weightlog-icon {
    height: 54px;
}

.WeightLogEntry .weightlog-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(7px + 1vw);
    font-weight: 700;
    color: #777777;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
}

.WeightLogEntry .weightlog-title.active {
    font-weight: 700;
    color: #333;
}

.WeightLogEntry .weight-main-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.WeightLogEntry .weight-main-title span{
    color: #777777;
    font-weight: 400;
}

.WeightLogEntry .form-label {
    font-family: "Open Sans", sans-serif;
    font-size: calc(9px + 1vw);
    font-weight: 700;
    color: #777777;
}

.WeightLogEntry .form-label1 {
    font-family: "Open Sans", sans-serif;
    font-size: calc(9px + 1vw);
    font-weight: 500;
    color: #777777;
    margin-bottom: 0.3rem;
}

.WeightLogEntry .form-label2 {
    font-family: "Open Sans", sans-serif;
    font-size: calc(9px + 1vw);
    font-weight: 600;
    color: #333;
    margin-bottom: 0rem;
}

.WeightLogEntry .weight-detail-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #777777;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
}

.weight-icon1 {
    height: 42px;
}

.WeightLogEntry .row.py-3.border-bottom:last-child {
    border: 0 !important;
    padding-bottom: 0rem !important;
}

.WeightLogEntryModule {
    border-radius: 20px;
    width: 80%;
    margin: 0 auto;
}

.WeightLogEntryModule .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;}


.WeightLogEntryModule .weight-btn {
        font-size: calc(14px + 1vw);
        font-weight: 600;
        color: #39B54A;
}

.picker-container .picker-column .picker-item.picker-item-selected {
    color: #222;
    font-weight: 600;
}