.JoinDiabeticWellnessPlan{
    background-color: var(--body-bg);
    padding: 10px;
    width: 100%;
}

.JoinDiabeticWellnessPlan .maintitle{
    font-size: calc(16px + 1vw);
    font-weight: 700;
    color: #327AC8;
    margin-bottom: 0rem;
}

.JoinDiabeticWellnessPlan .JDW-content-text{
    font-size: calc(12px + 1vw);
    font-weight: 500;
    color: #2F2F2F;
    margin-bottom: 0rem;
}

.JoinDiabeticWellnessPlan .h-10 {
    height: 42px;
}