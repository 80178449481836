
.WaterDetail {
    background-color: var(--body-bg);
    /* height: 100vh; */
    padding: 10px;
}

.WaterDetail .bg-water-card{
    background-color: #fff;
    border-radius:16px;
    padding: 0px 0px 10px 0px;
    border: 0.5px solid #C9C9C9;
}

.WaterDetail .water-main-title{
    font-size: calc(14px + 1vw);
    font-weight: 600;
    color: #242424;
    margin-bottom: 0rem;
}