.HomeHRA {
    background-color: var(--bgGrey);
}

.HomeHRA .HomeHRA-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}
