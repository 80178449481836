.HRAQuestionnaire{
    background-color: var(--body-bg);
    padding: 10px;
}

.HRAQuestionnaire .hra-main-title{
    font-size: calc(16px + 1vw);
    font-weight: 700;
    color: #327AC8;
    margin-bottom: 0rem;
}

.HRAQuestionnaire .submaintitle{
    font-size: calc(14px + 1vw);
    font-weight: 400;
    color: #141414;
    margin-bottom: 0rem;
    line-height: 1.4;
}

.HRAQuestionnaire .hra-title{
    font-size: calc(13px + 1vw);
    font-weight: 600;
    color: #272727;
    margin-bottom: 0rem;
}
