.FoodLog-calendar {
    padding: 5px;
}

.FoodLog-calendar .days-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(10px + 1vw);
    font-weight: 500;
    color: #747474;
    margin-bottom: 0.5rem;
}

.FoodLog-calendar .days-subtitle {
    font-family: "Open Sans", sans-serif;
    font-size: calc(10px + 1vw);
    font-weight: 600;
    color: #333;
    background-color: #f2f2f2;
    height: 28px;
    width: 28px;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.FoodLog-calendar .days-subtitle.active {
    font-weight: 700;
    color: #fff;
    background-color: #39A849;
}

.FoodLog-calendar .days-subtitle.inactive {
    color: #777777;
    background-color: #f2f2f2;
}

.FoodLog-calendar .calendar-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0rem;
    margin-top: 1rem;
}

.FoodLog-calendar .calendar-icon {
    height: 16px;
    vertical-align: baseline;
    padding-right: 5px;
}
.FoodLog-calendar .swiper-wrapper{
  display: flex;
  justify-content: space-between;
}


.days-subtitle {
    font-weight: normal; /* Reset font weight */
  }
  
  .days-subtitle.active,
  .selected .days-subtitle {
    font-weight: bold; /* Make active and selected dates bold */
  }
  .FoodLog-calendar .month-year {
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    font-size: calc(12px + 1vw);
    color: #4A90E2;
    font-weight: 600;
  }

  .arrow-btn {
    background-color: transparent;
    border: none;
    font-size: calc(22px + 1vw);
    cursor: pointer;
    outline: none;
    color: #333;
  }
  
  .arrow-btn:hover {
    color: #4A90E2; /* or any other color you prefer */
  }
  
  
  .row.align-items-center {
    justify-content: center;
  }

  .arrow-btn:disabled {
      color: #777777;
  }
  
/* 
  .arrow-btn {
    background-color: #327AC8;
    color: #f1efef;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .swiper-slide {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .swiper-slide:hover {
    transform: scale(1.05);
  }
  
  .month-year {
    font-size: 18px;
    font-weight: bold;
  } */
  