/* ErrorPage.css */

.error-container {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }
  
  .error-message {
    font-family: "Open Sans", sans-serif;
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    height: auto;
  }
  
  .error-image {
    width: 80%;
    margin-bottom: 30px;
  }
  
  .error-link {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
  }
  
  .error-link:hover {
    text-decoration: underline;
  }
  