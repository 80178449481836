
.FoodLog {
    background-color: #ffffff;
    opacity: 1;
    padding: 20px 10px;
    /* border-radius: 20px 20px 0 0; */
}

.FoodLog .main-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(16px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.main-btn {
    box-shadow: rgb(167 159 159 / 10%) 0px 0px 5px 0px, rgba(167 159 159 / 10%) 0px 0px 1px 0px;
    height: 110px  !important;
    width: 110px  !important;
    display: flex  !important;
    justify-content: center  !important;
    align-items: center  !important;
    flex-wrap: nowrap  !important;
    flex-direction: column  !important;
    margin: 0 auto  !important;
    border: 2px #EAEAEA solid !important;
    border-radius: 16px  !important;
}

.main-btn-active{
    color: #fff !important;
    background-color:#fff !important;
    border: 2px #36AE47 solid !important;
}

.main-btn-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 500;
    color: #2C2C2C;
    margin-bottom: 0rem;
    margin-top: 0.3rem;
}

.st1:hover{fill:#c10b0b !important;}

.floating-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Ensure the loader appears above other content */
  }
  
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db; /* Loader color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }