.CalendarHome {
    background-color: var(--bgGrey);
    padding: 20px 0px;
}

.CalendarHome .h-days-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(8px + 1vw);
    font-weight: 600;
    color: #353535;
    margin-bottom: 0.5rem;
}

.CalendarHome .h-days-subtitle {
    font-family: "Open Sans", sans-serif;
    font-size: calc(10px + 1vw);
    font-weight: 500;
    color: #707070;
    background-color: var(--bgGrey);
    height: 30px;
    width: 30px;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: 1px solid #707070;
}

.CalendarHome .h-days-subtitle.active {
    font-weight: 600;
    color: #fff;
    background-color: #327AC8;
    border: 1px solid #327AC8;
}

.CalendarHome .h-days-subtitle.inactive {
    color: #707070;
    background-color: var(--bgLightGrey);
    border: 1px solid #e5e8eb;
}

.CalendarHome .h-calendar-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.CalendarHome .h-calendar-title i {
    color: #327AC8;
}


.CalendarHome input#inputDate {
    border: 0px !important;
    border-bottom: 0px solid #333 !important;
    border-radius: 0px !important;
    padding: 0;
    font-size: calc(12px + 1vw);
    font-weight: 500;
    z-index: 2;
    position: relative;
    background: transparent !important;
    padding-left: 30px;
}

.CalendarHome input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
}

.CalendarHomeModule {
    border-radius: 20px;
    width: 80%;
    margin: 0 auto;
}

.CalendarHomeModule .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding:4px 10px;
}

.CalendarHomeModule .weight-btn {
        font-size: calc(14px + 1vw);
        font-weight: 600;
        color: #39B54A;
}

.picker-container .picker-column .picker-item.picker-item-selected {
    color: #222;
    font-weight: 600;
}

.CalendarHome .myCalendarFlex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 0.7rem;
}

.CalendarHome .swiper-slide:last-child {
    margin-right: 0px !important;
}