.HabitsProgress {
    background-color: var(--bgGrey);
}

.HabitsProgress .HabitsProgress-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}

.HabitsProgress .habit-box{
    background-color: var(--bgWhite);
    border-radius: 14px;
    padding: 10px;
}

.HabitsProgress .habit-icon{
    height: 28px;
}

.HabitsProgress .habit-box-title {
    font-size: calc(11px + 1vw);
    font-weight: 600;
    color: #242424;
    margin-bottom: 0rem;
}

.HabitsProgress .habit-box-subtitle {
    font-size: calc(8px + 1vw);
    font-weight: 500;
    color: #898989;
    margin-bottom: 0rem;
}

.HabitsProgress .habit-dates-c{
    display: flex;
    justify-content: space-between;
    margin-top: 0.4rem;
}

.HabitsProgress .habit-day-box {
    font-family: "Open Sans", sans-serif;
    font-size: calc(8px + 1vw);
    font-weight: 500;
    color: #707070;
    background-color: #E5E8EB;
    height: 24px;
    width: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.HabitsProgress .habit-day-box.active {
    background-color: #327AC8;
    color: #fff;
    font-weight: 600;
}

.HabitsProgress .habit-tick-icon{
    height: 34px;
}

