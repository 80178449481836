.JoinCommunity {
    background-color: var(--bgGrey);
}

.JoinCommunity .Community-title {
    font-family: "Open Sans", sans-serif;
    font-size: calc(12px + 1vw);
    font-weight: 600;
    color: #327AC8;
    margin-bottom: 0rem;
}

.JoinCommunity .JoinCommunity-box{
    background-color: var(--bgWhite);
    border-radius: 14px;
    padding: 0px;
    width: 100%;
}

.JoinCommunity .Community-img {
    border: 1px solid #e8edf7;
    border-radius: 14px;
    display: block;
    height: 140px;
    object-fit: cover;
    width: 100%;
}

.JoinCommunity .Community-box-gredient{
    width: 100%;
    padding: 10px;
    border-radius: 0 0 14px 14px;
}

.JoinCommunity .Community-box-title {
    font-size: calc(9px + 1vw);
    font-weight: 600;
    color: #2E2F30;
    margin-bottom: 0rem;
}

.JoinCommunity .Community-box-title span{
    font-size: calc(7px + 1vw);
    color: #898989;
}