.HealthRiskAssessment{
    background-color: var(--body-bg);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
}

.HealthRiskAssessment .maintitle{
    font-size: calc(16px + 1vw);
    font-weight: 700;
    color: #327AC8;
    margin-bottom: 0rem;
}

.HealthRiskAssessment .submaintitle{
    font-size: calc(14px + 1vw);
    font-weight: 400;
    color: #141414;
    margin-bottom: 0rem;
}
